import { Backdrop, Box, Button, Fade, Modal } from "@mui/material";

type ModelProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const styleGreen = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  maxHeight: "85%",
  background: "red",
  borderRadius: "10px",
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
  outline: "none",
  overflow: "hidden",
  overflowY: "hidden",
  scrollbarWidth: "none",
  scrollbarColor: "transparent transparent",
  p: 4,
};

export const QISwarning = ({ open, setOpen }: ModelProps) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={styleGreen}>
          <div
            style={{
              margin: "10px",
              textAlign: "center",
              color: "white",
            }}
          >
            WARNING: Pack voltage {">"} 0.45V. Rebalance cells before assembly.
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              variant="contained"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
