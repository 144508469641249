import { Button, Modal } from "@mui/material";

type ModelProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  validation: string[];
  step?: any;
};

export const ValidationModel = ({
  open,
  setOpen,
  validation,
  step,
}: ModelProps) => {
  return (
    <Modal
      open={open}
      // onClose={() => setOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div
        className={`general_popup ${
          step === "step_27" ? "bg-red" : "bg-white"
        } validation_popup`}
      >
        <div className={`modal_header `}>
          <p className={` ${step === "step_27" ? "text-white" : "text-black"}`}>
            Error!
          </p>
        </div>

        <div
          className={`model_dataWrapper ${
            step === "step_27" ? "text-white" : "text-black"
          }`}
        >
          {validation &&
            validation.length > 0 &&
            validation.map((val) => {
              if (typeof val === "string") {
                return <p key={val}>{val}</p>;
              }
              return null;
            })}
        </div>

        {/* <div className="model-footer">
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </div> */}
        <div className="modal_footer">
          <Button variant="contained" onClick={() => setOpen(false)}>
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};
