import React from "react";

const Footer = ({
  handleChange,
  tab,
  className,
}: {
  handleChange: any;
  tab: any;
  className: string;
}) => {
  return (
    <div className={`footer ${className}`}>
      <div className="footer-con">
        <p
          onClick={(e) => {
            e.stopPropagation();
            handleChange("intake");
          }}
          className={`${tab === "intake" ? "active" : "not-active"}`}
        >
          Intake
        </p>
        <p
          onClick={(e) => {
            e.stopPropagation();
            handleChange("inspection");
          }}
          className={`${tab === "inspection" ? "active" : "not-active"}`}
        >
          Inspection
        </p>
        <p
          onClick={(e) => {
            e.stopPropagation();
            handleChange("assembly");
          }}
          className={`${tab === "assembly" ? "active" : "not-active"}`}
        >
          Assembly
        </p>
        <p
          onClick={(e) => {
            e.stopPropagation();
            handleChange("deployment");
          }}
          className={`${tab === "deployment" ? "active" : "not-active"}`}
        >
          Deployment
        </p>
        <p
          onClick={(e) => {
            e.stopPropagation();
            handleChange("reverse");
          }}
          className={`${tab === "reverse" ? "active" : "not-active"}`}
        >
          Reverse Logistics
        </p>
        <p
          onClick={(e) => {
            e.stopPropagation();
            handleChange("recycling");
          }}
          className={`${tab === "recycling" ? "active" : "not-active"}`}
        >
          Recycling
        </p>
      </div>
    </div>
  );
};

export default Footer;
