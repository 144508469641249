import * as React from "react";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import { CiCircleChevDown } from "react-icons/ci";

const dropdown = [
  { name: "Intake", id: "intake" },
  { name: "Inspection", id: "inspection" },
  { name: "Assembly", id: "assembly" },
  { name: "Deployment", id: "deployment" },
  { name: "Reverse Logistics", id: "reverse" },
  { name: "Recycling", id: "recycling" },
];

const getNameById = (id: string): string | undefined => {
  const item = dropdown.find((entry) => entry.id === id);
  return item ? item.name : undefined;
};

export default function MenuSimple({
  handleChange,
  tab,
}: {
  handleChange: any;
  tab: any;
}) {
  const createHandleMenuClick = (menuItem: any) => {
    console.log(`Clicked on ${menuItem}`);
    handleChange(menuItem);
  };

  return (
    <Dropdown>
      <MenuButton>
        {getNameById(tab)}
        <CiCircleChevDown style={{ width: "18px", height: "18px" }} />
      </MenuButton>
      <Menu slots={{ listbox: Listbox }}>
        {dropdown.map((obj) => (
          <MenuItem
            key={obj.id}
            onClick={(e) => {
              e.stopPropagation();
              createHandleMenuClick(obj.id);
            }}
            className={obj.id === tab ? "active" : ""}
          >
            {obj.name}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  z-index: 1;
  `
);

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &.active {
    background-color: ${blue[200]};
    color:${grey[700]}
  }

  &:last-of-type {
    border-bottom: none;
  }

   &:focus {
     outline: 1px solid ${
       theme.palette.mode === "dark" ? blue[600] : blue[200]
     };
    //  background-color: ${
      theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
     color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
   }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }
  `
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 150ms ease;
  cursor: pointer;
  display:flex;
  align-items:center;
  gap:8px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);
