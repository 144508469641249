import { Field, FieldMap, sopMap } from "./types";

export const fieldmap: FieldMap = {
  step_16: {
    title: "Scheduler Dashboard",
    fields: [
      {
        key: "schedule",
        name: "Scheduler Dashboard",
        type: "field",
      },
    ],
  },
  step_14: {
    title: "KPI Dashboard",
    fields: [
      {
        key: "kpi",
        name: "Key Performance Indicator",
        type: "field",
      },
    ],
  },
  step_1: {
    title: "Incoming Lot",
    fields: [
      {
        key: "identifier",
        name: "Scan Lot ID",
        type: "field",
      },
      {
        key: "oem",
        name: "OEM",
        type: "select",
        option: [],
        optionVal: "code",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Add Another",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_2: {
    title: "Incoming Package Inspection",
    view_media: [
      {
        type: "image",
        key: "pallet_image",
        name: "Sample Pallet Image",
        src: "pallet_3.png",
      },
    ],
    upload_media: [
      {
        type: "image",
        key: "image",
        name: "Uploaded Pallet Image",
      },
    ],
    form_content: [
      { name: "Pallet Number", key: "identifier" },
      { name: "Pallet Weight", key: "weight" },
    ],
    fields: [
      {
        key: "shipment_lot",
        name: "Scan Lot ID",
        description: "Scan Lot ID",
        type: "select",
        option: [],
        optionVal: "identifier",
      },
      {
        key: "identifier",
        name: "Pallet ID",
        description: "Desc",
        type: "field",
      },
      {
        key: "weight",
        name: "Pallet Weight (lbs)",
        type: "field",
        validate: {
          range: [300, 3000],
        },
      },
      {
        key: "image",
        name: "Pallet Image",
        type: "file",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Add Another",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_3: {
    title: "Inbound WIP",
    fields: [
      {
        key: "shipment_lot",
        name: "Incoming Lot ID",
        type: "select",
        option: [],
        optionVal: "identifier",
      },
      {
        key: "pallet",
        name: "Scan Pallet ID",
        type: "select",
        option: [],
        optionVal: "identifier",
      },
      {
        key: "oem",
        name: "Select OEM",
        type: "select",
        option: ["Nissan", "Toyota", "Honda"],
        optionVal: "code",
      },
      {
        key: "oem_model",
        name: "Select Model",
        type: "select",
        option: ["Leaf Gen 1", "Leaf Gen 2", "Leaf Gen 3", "Leaf Gen 4"],
        optionVal: "code",
      },
      {
        key: "storage_bin",
        name: "Scan Inbound WIP Bin ID",
        type: "field",
      },
      {
        key: "module_count",
        name: "Enter number of modules to be placed in Bin",
        type: "field",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Add Another",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_4: {
    title: "Daily Prep",
    fields: [
      {
        key: "number_of_units",
        name: "Number of Unit",
        type: "field",
      },
      {
        key: "oem",
        name: "Select OEM",
        type: "select",
        option: ["Nissan", "Toyota", "Honda"],
        optionVal: "code",
      },
      {
        key: "oem_model",
        name: "Select Model",
        type: "select",
        option: ["Leaf Gen 1", "Leaf Gen 2", "Leaf Gen 3", "Leaf Gen 4"],
        optionVal: "code",
      },
      {
        key: "bin_id",
        name: "Bin Id",
        type: "field",
      },
      {
        key: "kwy_gen",
        name: "Generate Module Serial Number",
        type: "custom",
        action: "generate_serial_number",
        sidenav: false,
      },
    ],
  },
  step_18: {
    title: "HIS Progress",
    fields: [
      {
        key: "kpi1",
        name: "Scan Module ID",
        type: "HIS",
      },
    ],
  },
  // step_5: {
  //   title: "HIS 1: Physical Inspection",
  //   view_media: [
  //     {
  //       type: "image",
  //       key: "top_image",
  //       name: "Module Top Image",
  //       src: "HIS1_1.jpg",
  //     },
  //     {
  //       type: "image",
  //       key: "bottom_image",
  //       name: "Module Bottom Image",
  //       src: "HIS1_4.jpg",
  //     },
  //     {
  //       type: "image",
  //       key: "front_image",
  //       name: "Module Front Image",
  //       src: "HIS1_6.jpg",
  //     },
  //     {
  //       type: "image",
  //       key: "back_image",
  //       name: "Module Back Image",
  //       src: "HIS1_5.jpg",
  //     },
  //     {
  //       type: "image",
  //       key: "left_image",
  //       name: "Module Left Image",
  //       src: "HIS1_2.jpg",
  //     },
  //     {
  //       type: "image",
  //       key: "right_image",
  //       name: "Module Right Image",
  //       src: "HIS1_3.jpg",
  //     },
  //   ],
  //   upload_media: [
  //     {
  //       key: "top_image",
  //       name: "Step 1: Take Top image",
  //       type: "image",
  //     },
  //     {
  //       key: "bottom_image",
  //       name: "Step 2: Take Bottom image",
  //       type: "image",
  //     },
  //     {
  //       key: "front_image",
  //       name: "Step 3: Take Front image",
  //       type: "image",
  //     },
  //     {
  //       key: "back_image",
  //       name: "Step 4: Take Back Side image",
  //       type: "image",
  //     },
  //     {
  //       key: "left_image",
  //       name: "Step 5: Take Left Side image",
  //       type: "image",
  //     },
  //     {
  //       key: "right_image",
  //       name: "Step 6: Take Right Side image",
  //       type: "image",
  //     },
  //   ],
  //   fields: [
  //     {
  //       key: "battery_module",
  //       name: "Scan Module ID",
  //       type: "field",
  //     },
  //     {
  //       key: "oem_serial_id",
  //       name: "Scan OEM ID",
  //       type: "field",
  //     },
  //     {
  //       key: "weight",
  //       name: "Module Weight (lbs)",
  //       type: "field",
  //     },
  //     {
  //       key: "attached_module_id",
  //       name: "Scan Attached Module ID",
  //       type: "field",
  //       description:
  //         "Enter whitespace (press Space once) if you don't have attached Module",
  //     },
  //     {
  //       key: "top_image",
  //       name: "Step 1: Take Top image",
  //       type: "file",
  //     },
  //     {
  //       key: "bottom_image",
  //       name: "Step 2: Take Bottom image",
  //       type: "file",
  //     },
  //     {
  //       key: "front_image",
  //       name: "Step 3: Take Front Side image",
  //       type: "file",
  //     },
  //     {
  //       key: "back_image",
  //       name: "Step 4: Take Back Side image",
  //       type: "file",
  //     },
  //     {
  //       key: "left_image",
  //       name: "Step 5: Take Left Side image",
  //       type: "file",
  //     },
  //     {
  //       key: "right_image",
  //       name: "Step 6: Take Right Side image",
  //       type: "file",
  //     },
  //     {
  //       key: "module_thickness",
  //       name: "Module Thickness in mm",
  //       type: "field",
  //       validate: {
  //         range: [68, 73],
  //       },
  //     },
  //     {
  //       key: "rejection_reason",
  //       name: "Reject or Continue Processing",
  //       validate: {
  //         match: true,
  //       },
  //       type: "status",
  //       option: [
  //         "PHY1 - Bloated Module",
  //         "PHY2 - Physical Damage of casing",
  //         "PHY3 - Physical Damage of Terminal",
  //         "PHY4 - Corroded Terminals",
  //       ],
  //       sidenav: false,
  //     },
  //   ],
  // },
  // step_6: {
  //   title: "HIS 2: ACIR, OCV, Continuity",
  //   fields: [
  //     {
  //       key: "battery_module",
  //       name: "Scan Module ID",
  //       type: "field",
  //     },
  //     {
  //       key: "acir",
  //       name: "ACIR & OCV Measurement",
  //       type: "file_read",
  //       read_field: ["ocv", "acir"],
  //     },
  //     {
  //       key: "continuity",
  //       name: "Continuity",
  //       type: "select",
  //       option: ["true", "false"],
  //       validate: {
  //         match: "true",
  //       },
  //     },
  //     {
  //       key: "rejection_reason",
  //       name: "Reject or Continue Processing",
  //       type: "status",
  //       option: [
  //         "ELC1 - Low OCV",
  //         "ELC2 - High ACIR",
  //         "ELC3 - Continous Both Ways",
  //       ],
  //       sidenav: false,
  //     },
  //   ],
  // },
  // step_7: {
  //   title: "HIS 3: HiPOT",
  //   fields: [
  //     {
  //       key: "battery_module",
  //       name: "Scan Module ID",
  //       type: "field",
  //     },
  //     {
  //       key: "hipot_measurement",
  //       name: "HiPOT Measurement",
  //       type: "file_read",
  //       read_field: ["hipot_measurement"],
  //       file_validate: [{ field: "hipot_measurement", min: 1 }],
  //     },
  //     {
  //       key: "rejection_reason",
  //       name: "Reject or Continue Processing",
  //       type: "status",
  //       option: ["ELS1 - Module Casing Shorted"],
  //       sidenav: false,
  //     },
  //   ],
  // },
  // step_8: {
  //   title: "HIS 4: HPPC/Relaxation",
  //   fields: [
  //     {
  //       key: "battery_module",
  //       name: "Scan Module ID",
  //       type: "field",
  //     },
  //     {
  //       key: "test_channel_id",
  //       name: "Scan Test Channel ID",
  //       type: "field",
  //     },
  //     {
  //       key: "recipe_excel",
  //       name: "Recipe Image",
  //       type: "file",
  //       hidden: false,
  //     },
  //     {
  //       key: "next_step",
  //       type: "reset",
  //       name: "Add Another",
  //       hidden: false,
  //       sidenav: false,
  //     },
  //   ],
  // },
  // step_9: {
  //   title: "HIS 5: EIS & ACIR",
  //   fields: [
  //     {
  //       key: "battery_module",
  //       name: "Scan Module ID",
  //       type: "field",
  //     },
  //     {
  //       key: "test_channel_id_acir",
  //       name: "Scan Test Channel ID ACIR",
  //       type: "field",
  //     },
  //     {
  //       key: "acir_measurement_file",
  //       name: "ACIR Measurement",
  //       type: "file",
  //     },
  //     {
  //       key: "test_channel_id_eis",
  //       name: "Scan Test Channel ID EIS",
  //       type: "field",
  //     },
  //     {
  //       key: "eis_measurement_file",
  //       name: "EIS Measurement",
  //       type: "file",
  //     },
  //     {
  //       key: "next_step",
  //       type: "reset",
  //       name: "Add Another",
  //       hidden: false,
  //       sidenav: false,
  //     },
  //   ],
  // },
  // step_10: {
  //   title: "HIS 6: Capacity Measurement",
  //   fields: [
  //     {
  //       key: "battery_module",
  //       name: "Scan Module ID",
  //       type: "field",
  //     },
  //     {
  //       key: "test_channel_id",
  //       name: "Scan Test Channel ID",
  //       type: "field",
  //     },
  //     {
  //       key: "capacity_measurement_file",
  //       name: "Capacity Measurement",
  //       type: "file",
  //     },
  //     {
  //       key: "next_step",
  //       type: "reset",
  //       name: "Add Another",
  //       hidden: false,
  //       sidenav: false,
  //     },
  //   ],
  // },
  step_11: {
    title: "Sorting & Binning Process",
    fields: [
      {
        key: "module_id",
        name: "Scan Module ID",
        type: "field",
      },
    ],
  },
  step_12: {
    title: "Module Packaging",
    fields: [
      {
        key: "number_of_packs",
        name: "Enter number of packs to be shipped",
        type: "field",
      },
      {
        key: "oem",
        name: "Select Module OEM",
        type: "select",
        option: ["Nissan", "Toyota", "Honda"],
        optionVal: "code",
      },
      {
        key: "oem_model",
        name: "Select Module Model",
        type: "select",
        option: ["Leaf Gen 1", "Leaf Gen 2", "Leaf Gen 3", "Leaf Gen 4"],
        optionVal: "code",
      },
      {
        key: "modules_per_pack",
        name: "Enter number of modules/pack",
        type: "field",
      },
      {
        key: "soh_high",
        name: "Enter SOH High for product",
        type: "field",
      },
      {
        key: "soh_low",
        name: "Enter SOH Low for product",
        type: "field",
      },
      {
        key: "assembly_contractor",
        name: "Select Assembly Contractor",
        type: "select",
        option: ["Alchemy", "Precise Connections"],
      },
      {
        key: "assembly_location",
        name: "Select Assembly location",
        type: "select",
        option: ["HTX", "DTX"],
      },
      {
        key: "customer",
        name: "Customer Name",
        type: "select",
        option: ["ECAM", "Wanco", "NSI", "Ver-Mac","Testing"],
      },
      {
        key: "fieldSegment",
        name: "Field Segment",
        type: "select",
        option: ["Security Trailer", "Light Trailer","Testing"],
      },
      {
        key: "voltage",
        name: "Voltage",
        type: "select",
        option: ["15", "29", "58"],
      },
      {
        key: "bill_of_material",
        name: "Bill Of Material(BOM) ID",
        type: "select",
        option: ["001", "002", "003", "004"],
      },
      // {
      //   key: "final_save",
      //   name: "Generate Sorted Pack List",
      //   hidden: true,
      //   sidenav: false,
      //   type: "reset",
      // },
      {
        key: "submit",
        name: "",
        type: "submit",
        // option: ["rejection"],
        sidenav: false,
      },
    ],
  },
  step_13: {
    title: "Report",
    fields: [
      {
        key: "gen_report",
        name: "Publish",
        type: "custom",
        action: "publish_report",
      },
    ],
  },
  step_20: {
    title: "Report Bulk Download",
    fields: [
      {
        key: "bulk",
        name: "Bulk download",
        type: "field",
      },
    ],
  },
  step_15: {
    title: "Passport",
    fields: [
      {
        key: "gen_passport",
        name: "Publish",
        type: "custom",
        action: "publish_passport",
      },
    ],
  },
  step_19: {
    title: "Vendor",
    view_media: [
      {
        type: "image",
        key: "vendor_image",
        name: "Sample Pallet Image",
        src: "new1.jpeg",
      },
    ],
    upload_media: [
      {
        type: "image",
        key: "vendor_image",
        name: "Uploaded Pallet Image",
      },
    ],
    fields: [
      {
        key: "packId",
        name: "Pack ID",
        type: "select",
        option: [""],
        optionVal: "packId",
      },
      {
        key: "rejected_moduleId",
        name: "Reject Pack ID",
        type: "field",
      },
      {
        key: "replace_module",
        name: "Add/replace Pack ID",
        type: "field",
      },
      {
        key: "vendor_image",
        name: "Upload picture",
        type: "file",
      },
      {
        key: "vendor_excel",
        name: "Upload factory acceptance test",
        type: "excel",
      },
      {
        key: "vendor_save",
        name: "Continue",
        type: "custom",
        action: "generate_serial_number",
        sidenav: false,
      },
    ],
  },
  step_21: {
    title: "Assembler Portal",
    fields: [
      {
        key: "assembler",
        name: "Assembler Portal",
        type: "field",
      },
    ],
  },
  step_22: {
    title: "Customer Portal",
    fields: [
      {
        key: "customer",
        name: "Customer Portal",
        type: "field",
      },
    ],
  },
  step_23: {
    title: "Module Inspection",
    fields: [
      {
        key: "QIS",
        name: "Scan Pack ID",
        type: "fieldQIS",
      },
      {
        key: "voltageDifferenceOne",
        name: "Enter voltage cell One.",
        type: "field",
      },
      {
        key: "voltageDifferenceTwo",
        name: "Enter voltage cell Two.",
        type: "field",
      },
      {
        key: "voltageDifferenceThree",
        name: "Enter voltage cell Three.",
        type: "field",
      },
      {
        key: "voltageDifferenceFour",
        name: "Enter voltage cell Four.",
        type: "field",
      },
      {
        key: "heatShrinkOnTerminalStuds",
        name: "Heat shrink on terminal studs.",
        type: "checkbox",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Save",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_24: {
    title: "QIS 1 : Assembly 1",
    fields: [
      {
        key: "QIS",
        name: "Scan Pack ID",
        type: "fieldQIS",
      },
      {
        key: "applyHeating",
        name: "Apply heating element to each side of module",
        type: "checkbox",
      },
      {
        key: "fastenModulesWithTorque",
        name: "Fasten modules with mounting brackets. Torque to 11.4 N-m.",
        type: "checkbox",
      },
      {
        key: "threadZiptiesTopMountingPlate",
        name: "Thread three zip ties and secure top plate to battery stack. Apply anti-seize and torque to 4.5 N-m.",
        type: "checkbox",
      },
      {
        key: "mountActiveEqualizerAndParallelModule",
        name: "Mount Active Equalizer and Parallel Module. Apply Loctite 242 and torque to 0.6 N-m.",
        type: "checkbox",
      },
      {
        key: "applyQR",
        name: "Apply QR code sticker to top plate.",
        type: "checkbox",
      },
      {
        key: "mountBmsWithTape",
        name: "Mount BMS and heating module with VHB tape.",
        type: "checkbox",
      },
      {
        key: "mountHeaterModule",
        name: "Connect heating elements to heating module. Torque to 1.2 N-m.",
        type: "checkbox",
      },
      {
        key: "torqueDownHeaterModule",
        name: "Mount terminal protection base and bus bars.",
        type: "checkbox",
      },
      {
        key: "wiringHeaterModule",
        name: "Secure wiring to terminals. Apply Loctite 242 to bolts. Torque top and bottom rows to 5.5 N-m and middle row to 1.2 N-m.",
        type: "checkbox",
      },
      {
        key: "rotatePackPlaceTerminalProtection",
        name: "Apply Loctite 242 and hand-tighten screws to secure terminal protection cap.",
        type: "checkbox",
      },
      {
        key: "voltageDifference",
        name: "Enter voltage difference from Daly Detector of Cable Sequence device",
        type: "field",
      },
      {
        key: "connectParallelModuleAndBms",
        name: "Plug in and zip-tie remaining wiring",
        type: "checkbox",
      },
      {
        key: "positionFiveCable",
        name: "Position 5 cable mounts and secure wires with zip ties.",
        type: "checkbox",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Save",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_25: {
    title: "QIS 2 : Calibration",
    fields: [
      {
        key: "QIS",
        name: "Scan Pack ID",
        type: "fieldQIS",
      },
      {
        key: "tapeBmsThermistorToPack",
        name: "Tape thermistor to back of pack",
        type: "checkbox",
      },
      {
        key: "connectEqualizerAndBmsToYHarness",
        name: "Connect Active Equalizer, then BMS to Y-Harness.",
        type: "checkbox",
      },
      {
        key: "confirmVoltageTerminalToTerminal",
        name: "Enter voltage from bottom left negative to bottom right positive terminal.",
        type: "field",
      },
      {
        key: "configureBms",
        name: "Upgrade BMS firmware and apply configuration.",
        type: "checkbox",
      },
      {
        key: "confirmVoltageWireToWire",
        name: "Confirm voltage by testing wire to wire.",
        type: "field",
      },
      {
        key: "configureActiveEqualizer",
        name: "Configure Active Equalizer.",
        type: "checkbox",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Save",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_26: {
    title: "QIS 3 : Assembly 2",
    fields: [
      {
        key: "QIS",
        name: "Scan Pack ID",
        type: "fieldQIS",
      },
      {
        key: "placeCableMounts",
        name: "QC: harness connections, zipties, QR code, hardware secure, thermistor taped, etc.",
        type: "checkbox",
      },
      {
        key: "lowerPackIntoEnclosure",
        name: "Lower/secure pack to enclosure. Apply anti-seize and torque to 10.0 N-m.",
        type: "checkbox",
      },
      {
        key: "confirmLidGasketMounted",
        name: "Ensure lid has CANBUS connector, vent, and both Rehnotec terminals secured with Loctite 242 on screws.",
        type: "checkbox",
      },
      {
        key: "fastenWiresWithZipties",
        name: "Connect white and black wires to heating module.",
        type: "checkbox",
      },
      {
        key: "secureLidOnEnclosure1",
        name: "Mount lid gasket.",
        type: "checkbox",
      },
      {
        key: "ipqcWireConnectionsLugAndCan485",
        name: "Mount two white wires to red terminal and three black wires to black terminal. Apply Loctite 242 to bolts.",
        type: "checkbox",
      },
      {
        key: "connectCan485WiresToBms",
        name: "Connect CAN&485 wires from lid to BMS.",
        type: "checkbox",
      },
      {
        key: "ipqcModuleConnectionsZiptiesQrCode",
        name: "QC: 6 bolts, wires to terminals, CANBUS conection, gasket, etc.",
        type: "checkbox",
      },
      {
        key: "securePackToEnclosure",
        name: "Secure lid to enclosure. Apply anti-seize and torque to 8.0 N-m.",
        type: "checkbox",
      },
      {
        key: "applyQrCodeToTopMountingPlate",
        name: "Apply three labels to top of lid.",
        type: "checkbox",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Save",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_27: {
    title: "QIS 4 : Cycling",
    fields: [
      {
        key: "QIS",
        name: "Scan Pack ID",
        type: "fieldQIS",
      },
      {
        key: "cyclePack",
        name: "Charge pack to 100%",
        type: "checkbox",
      },
      {
        key: "confirmedFreezerTestConducted",
        name: "Conduct deep freeze heater test",
        type: "checkbox",
      },
      {
        key: "packQcCapacityVoltageCanComm",
        name: "Conduct DK cycle, analyze results, and save detailed report.",
        type: "checkbox",
      },
      {
        key: "uploadDataFromCycler",
        name: "Upload data from DK cycle",
        type: "excel",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Save",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_28: {
    title: "Pack Packaging",
    view_media: [
      {
        type: "image",
        key: "photoStationTop",
        name: "Photograph top of pack",
        src: "new1.jpeg",
      },
      {
        type: "image",
        key: "photoStationLeft",
        name: "Photograph Samsar side of pack",
        src: "new1.jpeg",
      },
      {
        type: "image",
        key: "photoStationRight",
        name: "Photograph warning label side of pack",
        src: "new1.jpeg",
      },
    ],
    upload_media: [
      {
        key: "photoStationTop",
        name: "Photograph top of pack",
        type: "image",
      },
      {
        key: "photoStationLeft",
        name: "Photograph Samsar side of pack",
        type: "image",
      },
      {
        key: "photoStationRight",
        name: "Photograph warning label side of pack",
        type: "image",
      },
    ],
    fields: [
      {
        key: "QIS",
        name: "Scan Pack ID",
        type: "fieldQIS",
      },
      {
        key: "applyManufacturedDateLabel",
        name: "Apply warning label with manufacturing date.",
        type: "checkbox",
      },
      {
        key: "wipeDownEnclosure",
        name: "Wipe down entire enclosure.",
        type: "checkbox",
      },
      {
        key: "attachRedAndBlackLugCovers",
        name: "Attach red and black lug covers.",
        type: "checkbox",
      },
      {
        key: "photoStationTop",
        name: "Photograph top of pack",
        type: "file",
      },
      {
        key: "photoStationLeft",
        name: "Photograph Samsar side of pack",
        type: "file",
      },
      {
        key: "photoStationRight",
        name: "Photograph warning label side of pack",
        type: "file",
      },
      {
        key: "tapeBoxAndLabel",
        name: "Box finished pack with packing foam, Board number label, and three hazardous labels.",
        type: "checkbox",
      },
      {
        key: "next_step",
        type: "reset",
        name: "Save",
        hidden: true,
        sidenav: false,
      },
    ],
  },
  step_29: {
    title: "Assembler KPI ",
    fields: [
      {
        key: "kpi",
        name: "Key Performance Indicator",
        type: "field",
      },
    ],
  },
  step_30: {
    title: "QIS Report",
    fields: [
      {
        key: "qis_passport",
        name: "Publish",
        type: "custom",
        action: "QIS_report",
      },
    ],
  },
  // step_17: {
  //   title: "Data",
  //   fields: [
  //     {
  //       key: "data",
  //       name: "Battery Data",
  //       type: "field",
  //     },
  //   ],
  // },
};

export const stepSop: Record<string, string> = {
  step_1:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EQetPxJvj3tMtFASpRSa6zoBqPF408rm0kAI-MbZxhqZSw?e=qPz1Dd",
  step_2:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EeFtvy8M4wJHrqsm-nG_K_gBWcg_-YeOnR1vAw4glPStzQ?e=Vw37Ba",
  step_3:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EfP_CbIzq_pHto8bD-nbfwgBAyztCH9nGmx_U3ZBqPXFug?e=yO8CpB",
  step_4:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/Ecd2zxnsPipIgXgcdzBUY9MB_8NUfWF6yt1M6nVI8auRmw?e=dHV61E",
  step_5:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/ERihfurHg8VFpG2GwdJ38MwBQYbhvuZzvrj0yiDPZkgaRQ?e=sJKq2y",
  step_6:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EWQ57pyAXY9HqdKBudkFKVkB2EpMize04vq0D0OnBhZUow?e=TxUcyd",
  step_7:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EVvotQPpO9JHqi0tbraEIlYBNcubHfzeJcLRHPYu8IsmsQ?e=OB9JDF",
  step_8:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EUzRR7UWOrFEssMH-RXVScEBahZkUG18_7KTEcRUXVmwRA?e=W5JhBc",
  step_9:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/Eea0SbO-XVZFqT39YHlRo54BGhLaPlg1e7zy4l62A9IR7w?e=2HigpV",
  step_10:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EWz99a6s5HBPuVfrzzq4crsB07Oy20WvRdR5Dcz5_N8ZNQ?e=3sfiEX",
  step_11:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EXLPlIfRv1FNgIEe5QvfIvEBMr8Y9wsdx2ndK491u3qK2g?e=XV86fc",
  step_12:
    "https://samsarresources-my.sharepoint.com/:w:/p/ggarcia/EW-2DbfMAFdAjimlQ56RNCsBoLxxvaSAESVJVVD4E8kzgQ?e=dnfoRp",
  step_18: ""
};

export const validationHandle = (
  validate: any,
  val: boolean | string | number,
  data: Field
) => {
  let isError = false;
  let infoDetails = "";
  for (const errorType in validate) {
    if (errorType === "range") {
      if (
        Number(val) < validate["range"][0] ||
        Number(val) > validate["range"][1]
      ) {
        isError = true;
        infoDetails = `${data.name} is out of range`;
      } else {
        infoDetails = `${data.name} is acceptable range`;
      }
    } else if (errorType === "match") {
      if (val != validate["match"]) {
        isError = true;
        infoDetails = `False`;
      } else {
        infoDetails = `Accepted`;
      }
    }
  }
  return { isError, infoDetails };
};

export const fileReadValidateHandle = (
  validate: any,
  val: boolean | string | number,
  field: string
) => {
  let isError = false;
  let infoDetails = "";

  if (validate.min) {
    if (val < validate.min) {
      isError = true;
      infoDetails = `${field} is out of range`;
    } else {
      infoDetails = `${field} is in range`;
    }
  }

  if (validate.max) {
    if (val > validate.max) {
      isError = true;
      infoDetails = `${field} is out of range`;
    } else {
      infoDetails = `${field} is in range`;
    }
  }

  return { isError, infoDetails };
};
