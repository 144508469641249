import { CloseCircleOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Button, FormControl, Input, InputLabel, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { handleFieldData } from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";

const FileField = ({
  data,
  step,
  value,
  status,
}: {
  data: Field;
  step: string;
  value: any;
  status: boolean;
}) => {
  const dispatch = useDispatch();
  const fileRef = React.useRef<any>(null);
  const [fieldValue, setFieldValue] = React.useState(value);
  const reset = useAppSelector((state: any) => state.inspection).toggle;

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [reset]);

  const handleSave = () => {
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: data.key,
      })
    );
  };

  const handleUpload = () => {
    if (fileRef) {
      fileRef.current.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files;
    if (file?.length > 0) {
      if (
        file[0]?.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file[0]?.type === "application/vnd.ms-excel" ||
        file[0]?.type === "text/csv"
      ) {
        setFieldValue(file[0]);
      } else {
        return;
      }
      fileRef.current.value = null;
    }
  };

  const handleRemoveFile = () => {
    fileRef.current.value = null;
    setFieldValue(null);
  };

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
      </InputLabel>
      <input
        hidden
        type="file"
        id={data.key}
        onChange={handleFileUpload}
        ref={fileRef}
      />
      {fieldValue ? (
        <div>
          <p>{fieldValue.name}</p>
          <CloseCircleOutlined onClick={handleRemoveFile} />
        </div>
      ) : (
        <Button variant="contained" onClick={handleUpload} disabled={!status}>
          <CloudUploadOutlined />
          Upload
        </Button>
      )}
      <Button
        variant="contained"
        onClick={handleSave}
        disabled={fieldValue ? false : true}
      >
        Save
      </Button>
    </FormControl>
  );
};

export default memo(FileField);
