import React, { useEffect, useState } from "react";
import SopHeader from "./StepForms/sopHeader";
import ReactApexChart from "react-apexcharts";
import Grid from "@mui/material/Grid";
// import axios from "axios"
import {
  getCount,
  getCountGraph,
  getCountQis,
  getFacilityQis,
  getGraph1,
  getGraph2,
  getGraph3,
  getGraph4,
  getGraph5,
  getGraphLast,
  getCountInventory,
  getCountContribution,
} from "../../service/apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Button, Tooltip, Typography } from "@mui/material";
import styled from "@emotion/styled";

const ChartContainer = styled.div`
  .apexcharts-xaxis-label,
  .apexcharts-yaxis-label {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    .apexcharts-xaxis-label,
    .apexcharts-yaxis-label {
      font-size: 10px;
    }
  }
`;
const QISKPI = () => {
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [loading8, setLoading8] = useState(false);
  const [loading9, setLoading9] = useState(false);
  const [loading10, setLoading10] = useState(false);
  const [loading11, setLoading11] = useState(false);
  const [loading12, setLoading12] = useState(false);

  const [timeArray, setTimeArray] = useState([]);

  const [graphModel, setGraphModel] = useState({
    label: "Gen 4",
    value: "",
    oem_id: "",
  });

  const [countData, setCountData] = React.useState<any>([]);
  const [countLoading, setCountLoading] = React.useState<boolean>(false);
  // console.log("%c Line:58 🍿 countData", "color:#f5ce50", countData)
  const [chart5Data, setChart5Data] = useState<[]>([]);
  const [data, setData] = useState<[]>([]);
  const [startDate1, setStartDate1] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [startDate2, setStartDate2] = useState("");
  const [endDate2, setEndDate2] = useState("");
  const [startDate3, setStartDate3] = useState("");
  const [endDate3, setEndDate3] = useState("");
  const [startDate5, setStartDate5] = useState("");
  const [endDate5, setEndDate5] = useState("");
  const [startDate6, setStartDate6] = useState("");
  const [endDate6, setEndDate6] = useState("");
  const [startDate7, setStartDate7] = useState("");
  const [endDate7, setEndDate7] = useState("");
  const [startDate8, setStartDate8] = useState("");
  const [endDate8, setEndDate8] = useState("");
  const [startDate9, setStartDate9] = useState("");
  const [endDate9, setEndDate9] = useState("");
  const [startDate10, setStartDate10] = useState("");
  const [endDate10, setEndDate10] = useState("");
  const [startDate11, setStartDate11] = useState("");
  const [endDate11, setEndDate11] = useState("");
  const [startDate12, setStartDate12] = useState("");
  const [endDate12, setEndDate12] = useState("");

  const handleGraph3 = ({ type }: { type: string }) => {
    // const oemValue = (OEMChart3 as any)?.value || ""
    // const modelValue = (modelChart3 as any)?.value || ""

    const params: any = {};
    if (type === "clear") {
      setStartDate3("");
      setEndDate3("");
    }
    if (type === "filter") {
      if (startDate3) {
        params.startDate = moment(startDate3).format("DD/MM/YYYY");
      }
      if (endDate3) {
        params.endDate = moment(endDate3).format("DD/MM/YYYY");
      }
    }
    setLoading3(true);
    getCountInventory(params)
      .then((res) => {
        console.log("%c Line:105 🥑 res", "color:#ed9ec7", res);
        if (res?.success && res?.data) {
          setGraph4({
            ...graph4,
            series: [
              {
                name: "Value",

                data: [
                  +res?.data?.data?.packagingCount,
                  +res?.data?.data?.inProcessCount,
                  +res?.data?.data?.completedCount,
                ] as any,
              },
            ],
          });
          setLoading3(false);
        } else {
          setGraph4({
            ...graph4,
            series: [
              {
                name: "Value",
                data: [],
              },
            ],
          });
          setLoading3(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading3(false);
      });
  };
  const handleGraph1 = ({ type }: { type: string }) => {
    // const oemValue = (OEMChart3 as any)?.value || ""
    // const modelValue = (modelChart3 as any)?.value || ""

    const params: any = {};
    if (type === "clear") {
      setStartDate1("");
      setEndDate1("");
    }
    if (type === "filter") {
      if (startDate1) {
        params.startDate = moment(startDate1).format("DD/MM/YYYY");
      }
      if (endDate1) {
        params.endDate = moment(endDate1).format("DD/MM/YYYY");
      }
    }
    setLoading1(true);
    getCountGraph(params)
      .then((res) => {
        // console.log("%c Line:105 🥑 res", "color:#ed9ec7", res);
        if (res?.success && res?.data) {
          // setChart1Data(res?.data?.data);
          setGraph2({
            ...graph2,
            series: [
              {
                name: "Work cell 1",
                group: "workcell1",
                data: [
                  +res?.data?.data?.moduleInspection,
                  +res?.data?.data?.qis1,
                  +res?.data?.data?.qis2,
                  +res?.data?.data?.qis3,
                  +res?.data?.data?.qis4,
                  +res?.data?.data?.packPackaging,
                ] as any,
              },
              {
                name: "Rejected",
                group: "workcell1",
                data: [
                  res?.data?.data?.moduleInspectionRejects
                    ? +res?.data?.data?.moduleInspectionRejects
                    : null,
                  res?.data?.data?.qis1Rejects
                    ? +res?.data?.data?.qis1Rejects
                    : null,
                  res?.data?.data?.qis2Rejects
                    ? +res?.data?.data?.qis2Rejects
                    : null,
                  res?.data?.data?.qis3Rejects
                    ? +res?.data?.data?.qis3Rejects
                    : null,
                  res?.data?.data?.qis4Rejects
                    ? +res?.data?.data?.qis4Rejects
                    : null,
                  res?.data?.data?.packPackagingRejects
                    ? +res?.data?.data?.packPackagingRejects
                    : null,
                ] as any,
              },

              {
                name: "Work cell 2",
                group: "workcell2",
                data: ["0", "0", "0", "0", "0", "0"] as any,
              },
            ],
          });
          setLoading1(false);
        } else {
          setGraph2({
            ...graph2,
            series: [
              {
                name: "Work cell 1",
                group: "workcell1",
                data: [],
              },
              {
                name: "Rejected",
                group: "workcell1",
                data: [],
              },
              {
                name: "Work cell 2",
                group: "workcell2",
                data: [],
              },
            ],
          });
          setLoading1(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading1(false);
      });
  };
  const handleGraph6 = () => {
    const params: any = {};

    setCountLoading(true);
    getCountQis(params)
      .then((res) => {
        console.log("res:", res);
        if (res?.data) {
          setCountData(res?.data?.data);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setCountLoading(false));
  };
  const handleGraph4 = () => {
    const params: any = {};

    getFacilityQis(params)
      .then((res) => {
        console.log("%c Line:105 🥑 res", "color:#ed9ec7", res);
        if (res?.success && res?.data) {
          const array: any = [];
          const array2: any = [];
          const today = new Date();
          for (let i = 29; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(date.getDate() - i);
            const dateString = date.toISOString().split("T")[0];

            const apiIndex = res?.data?.data
              ?.map((obj: any) => obj?.date)
              .indexOf(dateString);
            if (apiIndex !== -1) {
              array.push(res?.data?.data[apiIndex]);
            } else {
              array.push({ date: dateString, count: 0 });
            }
          }
          for (let i = 12; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(date.getDate() - i);
            const dateString = date.toISOString().split("T")[0];

            const apiIndex = res?.data?.data
              ?.map((obj: any) => obj?.date)
              .indexOf(dateString);
            if (apiIndex !== -1) {
              array2.push(res?.data?.data[apiIndex]);
            } else {
              array2.push({ date: dateString, count: 0 });
            }
          }
          // console.log("%c Line:266 🍐 array", "color:#93c0a4", array)
          setGraph5Two({
            ...graph5Two,
            series: [
              {
                name: "Work cell 1",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
              {
                name: "Work cell 2",
                data: array2?.map((obj: any) => "0") as any,
              },
            ],
            options: {
              ...graph5Two.options,
              xaxis: {
                labels: {
                  rotate: -45,
                },
                categories: array2?.map((obj: any) => obj?.date) as [],
                tickPlacement: "on",
              },
            },
          });
          setGraph8({
            ...graph8,
            series: [
              {
                name: "Work cell 1",
                data: res?.data?.data
                  ?.map((obj: any) => obj?.count)
                  .reverse() as any,
              },
              {
                name: "Work cell 2",
                data: array?.map((obj: any) => "0") as any,
              },
            ],
            options: {
              ...graph8.options,
              xaxis: {
                labels: {
                  rotate: -45,
                },
                categories: res?.data?.data
                  ?.map((obj: any) => obj?.date)
                  .reverse() as [],
                tickPlacement: "on",
              },
            },
          });
        } else {
          // setGraph8({
          //   ...graph8,
          //   series: [
          //     {
          //       name: "",
          //       // type: "bar",
          //       data: [],
          //     },
          //     {
          //       name: "Work cell 2",
          //       // type: "bar",
          //       data: [],
          //     },
          //   ],
          // });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleGraph2 = ({ type }: { type: string }) => {
    const params: any = {
      // rejected: "true",
    };
    if (type === "clear") {
      setStartDate2("");
      setEndDate2("");
    }
    if (type === "filter") {
      if (startDate2) {
        params.startDate = moment(startDate2).format("DD/MM/YYYY");
      }
      if (endDate2) {
        params.endDate = moment(endDate2).format("DD/MM/YYYY");
      }
    }
    setLoading7(true);
    getGraph2(params)
      .then((res) => {
        console.log("%c Line:105 🍆 res", "color:#42b983", res);
        if (res?.success && res?.data) {
          const arr = [
            { label: "PHY1", value: +res?.data?.data?.PHY1 },
            {
              label: "PHY2",
              value: +res?.data?.data?.PHY2,
            },
            {
              label: "PHY3",
              value: +res?.data?.data?.PHY3,
            },
            {
              label: "PHY4",
              value: +res?.data?.data?.PHY4,
            },
            {
              label: "ECL1",
              value: +res?.data?.data?.ECL1,
            },
            {
              label: "ECL2",
              value: +res?.data?.data?.ECL2,
            },
            {
              label: "ECL3",
              value: +res?.data?.data?.ECL3,
            },
            {
              label: "ELS1",
              value: +res?.data?.data?.ELS1,
            },
            {
              label: "ECH5",
              value: +res?.data?.data?.ECH5,
            },
            {
              label: "ECH6",
              value: +res?.data?.data?.ECH6,
            },
            {
              label: "ECH1",
              value: +res?.data?.data?.ECH1,
            },
            {
              label: "ECH2",
              value: +res?.data?.data?.ECH2,
            },
            {
              label: "ECH3",
              value: +res?.data?.data?.ECH3,
            },
            {
              label: "ECH4",
              value: +res?.data?.data?.ECH4,
            },
            // {
            //   label: " Tolerance ",
            //   value: +res?.data?.data?.tolerance,
            // },
            // {
            //   label: " Insulation Resistance ",
            //   value: +res?.data?.data?.insulationResistance,
            // },
          ];
          const total = arr.reduce(
            (acc: number, crr: any) => acc + crr.value,
            0
          );
          const sortedData = arr
            .slice()
            .sort((a: any, b: any) => b.value - a.value);
          const lineData = sortedData?.map(
            (obj: any) => +((obj?.value / total) * 100)?.toFixed(2)
          );
          const cumulativeTotal: number[] = [];

          lineData.reduce((acc: number, curr: any) => {
            const currentTotal = acc + curr;
            cumulativeTotal.push(currentTotal);

            // console.log("cumulativeTotal: ", cumulativeTotal);
            return currentTotal;
          }, 0);

          const sortedLabels = sortedData.map((item) => item.label);
          const sortedValues = sortedData.map((item) => +item.value);
          // console.log("sortedValues: ", sortedValues);
          // setGraph7({
          //   series: [
          //     {
          //       name: "Value",
          //       type: "bar",
          //       data: sortedValues as [],
          //     },
          //     {
          //       name: "Cumulative",
          //       type: "line",
          //       data: cumulativeTotal as [],
          //     },
          //   ],
          //   options: {
          //     ...graph7.options,
          //     xaxis: {
          //       labels: {
          //         rotate: -40,
          //         // rotateAlways: true,
          //         style: {
          //           fontSize: "12px",
          //         },
          //         tickAmount: 10,
          //       },
          //       categories: sortedLabels as [],
          //       tickPlacement: "on",
          //     },
          //   },
          // });
          setLoading7(false);
        } else {
          setLoading7(false);
          // setGraph7({
          //   ...graph7,
          //   series: [
          //     {
          //       name: "Value",
          //       type: "bar",
          //       data: [],
          //     },

          //     {
          //       name: "Cumulative",
          //       type: "line",
          //       data: [],
          //     },
          //   ],
          // });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading2(false);
      });
  };
  const handleGraph5 = () => {
    const params: any = {};
    if (startDate5) {
      params.startDate = moment(startDate5).format("DD/MM/YYYY");
    }
    if (endDate5) {
      params.endDate = moment(endDate5).format("DD/MM/YYYY");
    }

    getGraph5(params)
      .then((res) => {
        if (res?.data) {
          setChart5Data(res?.data?.data);
          const filteredArray: [] = res?.data?.data.filter((obj: any) => {
            return (
              obj.dailyPrep !== "0" ||
              obj.his1 !== "0" ||
              obj.his2 !== "0" ||
              obj.his3 !== "0" ||
              obj.his4 !== "0" ||
              obj.his5 !== "0" ||
              obj.his6 !== "0" ||
              obj.packaging !== "0"
            );
          });

          const arr = filteredArray?.filter(
            (obj: any) => obj?.username !== "zeal" && obj?.username !== "admin"
          );
          setChart5({
            ...chart5,
            series: arr?.map((obj: any) => {
              return {
                name: obj?.username,
                data: [
                  obj?.dailyPrep,
                  obj?.his1,
                  obj?.his2,
                  obj?.his3,
                  obj?.his4,
                  obj?.his5,
                  obj?.his6,
                  obj?.packaging,
                ],
              };
            }),
          });
        } else {
          setChart5Data(res?.data?.data);
          setChart5({
            ...chart5,
            series: res?.data?.map((obj: any) => {
              return {
                name: obj?.username,
                data: [obj?.his1, obj?.his2, obj?.his3, obj?.his4],
              };
            }),
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleGraphLast = ({ type }: { type: string }) => {
    const params: any = {};
    if (type === "clear") {
      setStartDate5("");
      setEndDate5("");
    }
    if (type === "filter") {
      if (startDate5) {
        params.startDate = moment(startDate5).format("DD/MM/YYYY");
      }
      if (endDate5) {
        params.endDate = moment(endDate5).format("DD/MM/YYYY");
      }
    }
    setLoading5(true);
    getCountContribution(params)
      .then((res: any) => {
        console.log("%c Line:785 🍇 res", "color:#4fff4B", res);
        if (res?.status) {
          const dmayfield: [] =
            res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.find((obj: any) => obj?.name === "dmayfield");
          const jott: [] =
            res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.find((obj: any) => obj?.name === "jott");
          const smoises: [] =
            res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.find((obj: any) => obj?.name === "smoises");
          const mmcmeans: [] =
            res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.find((obj: any) => obj?.name === "mmcmeans");
          const arr: any = [dmayfield, jott, smoises, mmcmeans]?.filter(
            Boolean
          );

          if (arr.length > 0) {
            setTimeArray(arr);
          }
        }
      })
      .catch((e: any) => {
        console.log("%c Line:788 🥝 e", "color:#e41a6a", e);
      })
      .finally(() => setLoading5(false));
  };

  useEffect(() => {
    handleGraph1({ type: "clear" });
    // handleGraph2({ type: "clear" });
    handleGraph3({ type: "clear" });
    handleGraphLast({ type: "clear" });
  }, []);

  useEffect(() => {
    // handleGraph1();
    // handleGraph2();
    // handleGraph3();
    handleGraph4();
    handleGraph6();
  }, []);

  // useEffect(() => {
  //   handleGraph5();
  // }, [modelChart5, OEMChart5, startDate5, endDate5]);

  const [graph2, setGraph2] = useState({
    series: [
      {
        name: "Work cell 1",
        group: "workcell1",
        data: data,
        // data: [26, 22, 18, 15, 10, 8],
      },
      {
        name: "Rejected",
        group: "workcell1",
        data: data,
        // data: [10, 12, 1, 5, 4, 1],
      },
      {
        name: "Work cell 2",
        group: "workcell2",
        data: data,
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
        stacked: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      graph: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          "Module inspection",
          "QIS 1",
          "QIS 2",
          "QIS 3",
          "QIS 4",
          "Pack packaging",
        ],
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      colors: ["#008FFB", "#FF4560", "#00e396"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          // console.log("%c Line:902 🍩 w", "color:#ea7e5c", w);
          const group = w.config.series[seriesIndex].group;
          const seriesInGroup = w.config.series.filter(
            (s: any) => s.group === group
          );
          const tooltipItems = seriesInGroup
            .map((s: any) => {
              const value = s.data[dataPointIndex];
              return (
                value && `<div><span>${s.name}: <b>${value}</b></span></div>`
              );
            })
            .filter(Boolean)
            .join("");

          const totalValue = seriesInGroup.reduce((total: number, s: any) => {
            const value = s.data[dataPointIndex];
            return total + (value || 0);
          }, 0);
          return (
            "<div class='tooltip'>" +
            "<h3><b>" +
            w.globals.categoryLabels[dataPointIndex] +
            "</b></h3>" +
            "<ul>" +
            "<li>" +
            tooltipItems +
            "</li>" +
            "<li>" +
            `<span>Total: <b>${totalValue}</b></span></div>` +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
      },
    },
  });

  // const [graph8, setGraph8] = useState({
  //   series: [
  //     {
  //       name: "Work cell 1",
  //       type: "bar",
  //       data: [],
  //     },
  //     {
  //       name: "Work cell 2",
  //       type: "bar",
  //       data: [],
  //     },
  //   ],
  //   options: {
  //     annotations: {
  //       points: [
  //         {
  //           x: "Bananas",
  //           seriesIndex: 0,
  //           label: {
  //             borderColor: "#775DD0",
  //             offsetY: 0,
  //             style: {
  //               color: "#fff",
  //               background: "#775DD0",
  //             },
  //             text: "Bananas are good",
  //           },
  //         },
  //       ],
  //     },
  //     chart: {
  //       height: 350,
  //       type: "bar",
  //     },
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 0,
  //         columnWidth: "50%",
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       width: 2,
  //     },
  //     // title: {
  //     //   text: "Throughput",
  //     // },
  //     grid: {
  //       row: {
  //         colors: ["#fff", "#f2f2f2"],
  //       },
  //     },
  //     xaxis: {
  //       labels: {
  //         rotate: -45,
  //       },
  //       categories: [],
  //       tickPlacement: "on",
  //     },
  //     yaxis: {
  //       title: {
  //         text: "Number of Modules",
  //       },
  //     },
  //     fill: {
  //       type: "gradient",
  //       gradient: {
  //         shade: "light",
  //         type: "vertical",
  //         shadeIntensity: 0.25,
  //         gradientToColors: undefined,
  //         inverseColors: false,
  //         opacityFrom: 0.85,
  //         opacityTo: 0.85,
  //         // stops: [50, 0, 100],
  //       },
  //     },
  //   },
  // })
  const defectsName = (e: any) => {
    if (e === "PHY1") {
      return "Bloated Module(PHY1)";
    } else if (e === "PHY2") {
      return "Physical Damage of casing(PHY2)";
    } else if (e === "PHY3") {
      return "Physical Damage of Terminal(PHY3)";
    } else if (e === "PHY4") {
      return "Corroded Terminals(PHY4)";
    } else if (e === "ECL1") {
      return "Low OCV (ECL1)";
    } else if (e === "ECL2") {
      return "High ACIR (ECL2)";
    } else if (e === "ECL3") {
      return "Continous Both Ways (ECL3)";
    } else if (e === "ELS1") {
      return "Improper insulation (ELS1)";
    } else if (e === "ECH5") {
      return "Interrupted HPPC Test (ECH5)";
    } else if (e === "ECH6") {
      return "Bad EIS sweep (ECH6)";
    } else if (e === "ECH1") {
      return "Low SoH (ECH1)";
    } else if (e === "ECH2") {
      return "High ΔSoH for Linked Modules(ECH2)";
    } else if (e === "ECH3") {
      return "Interrupted Test:Over Heating(ECH3)";
    } else if (e === "ECH4") {
      return "Interrupted Test:Under Control Temperature(ECH4)";
    }
  };

  const [graph4, setGraph4] = useState({
    series: [
      {
        name: "Value",
        data: [0, 0, 0, 0],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          "Module Packed",
          "Packs in Process",
          "Completed Packs",
        ],
        tickPlacement: "on",
      },

      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const [graph5One, setGraph5One] = useState({
    series: [
      {
        name: "Value",
        data: [0, 0, 0, 0],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          "Modules in Queue",
          "Packs in Process",
          "Task complete",
          "Raw Materials",
        ],
        tickPlacement: "on",
      },

      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const [graph5Two, setGraph5Two] = useState({
    series: [
      {
        name: "Work cell 1",
        data: data,
      },
      {
        name: "Work cell 2",
        data: data,
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        labels: {
          text: "Shift1, Shift2, Shift3",
        },
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [],
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return (
            "<div class='tooltip'>" +
            "<h3><b>" +
            w.config.series[seriesIndex].name +
            "</b></h3>" +
            "<ul>" +
            "<li>Shift1 : " +
            data +
            "</li>" +
            "<li>Shift2 : " +
            0 +
            "</li>" +
            "<li>Shift3 : " +
            0 +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
        enabled: true,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const [graph6, setGraph6] = useState({
    series: [
      {
        name: "Value",
        data: [0, 0, 0],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: ["Open", "Due", "Executed"],
        tickPlacement: "on",
      },

      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const [graph7, setGraph7] = useState({
    series: [
      {
        name: "Value",
        type: "bar",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Cumulative",
        type: "line",
        data: [0, 0, 0, 0, 0],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },

      chart: {
        height: 400,
        type: "bar",
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -50,
          // rotateAlways: true,
          style: {
            fontSize: "12px",
          },
          tickAmount: 10,
        },
        categories: [
          "Defect 1",
          "Defect 2",
          "Defect 3",
          "Defect 4",
          "Defect 5",
        ],
        tickPlacement: "on",
      },
      yaxis: [
        {
          title: {
            text: "Number of Packs",
          },
          labels: {
            formatter: (value: number) => {
              return Number.isInteger(value) ? value : "";
            },
          },
        },
        {
          opposite: true,
          max: 100,
          min: 0,
          labels: {
            formatter: (value: any) => `${value.toFixed(0)}%`,
          },
          title: {
            text: "Cumulative percentage",
          },
        },
      ],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return (
            "<div class='tooltip'>" +
            "<h3 >" +
            defectsName(w.globals.categoryLabels[dataPointIndex]) +
            "</h3>" +
            "<ul>" +
            "<li>Value : " +
            "<b>" +
            data +
            "</b>" +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
        enabled: true,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const [graph8, setGraph8] = useState({
    series: [
      {
        name: "Work cell 1",
        data: data,
      },
      {
        name: "Work cell 2",
        data: data,
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        labels: {
          text: "Shift1, Shift2, Shift3",
        },
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [],
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return (
            "<div class='tooltip'>" +
            "<h3><b>" +
            w.config.series[seriesIndex].name +
            "</b></h3>" +
            "<ul>" +
            "<li>Shift1 : " +
            data +
            "</li>" +
            "<li>Shift2 : " +
            0 +
            "</li>" +
            "<li>Shift3 : " +
            0 +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
        enabled: true,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const [graph9, setGraph9] = useState({
    series: [
      {
        name: "Value",
        data: [0, 0, 0],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          show: false,
          rotate: -45,
        },
        categories: ["Open", "Due", "Executed"],
        tickPlacement: "on",
      },

      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const [graph10, setGraph10] = useState({
    series: [
      {
        name: "Value",
        data: [0, 0, 0],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          show: false,
          rotate: -45,
        },
        categories: ["Open", "Due", "Executed"],
        tickPlacement: "on",
      },

      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });

  const [graph11, setGraph11] = useState({
    series: [
      {
        name: "Value",
        data: [0, 0, 0],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
          show: false,
        },
        categories: ["Open", "Due", "Executed"],
        tickPlacement: "on",
      },

      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const getLastSevenDates = () => {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const pastDate = new Date(today);
      pastDate.setDate(today.getDate() - i);
      dates.push(pastDate.toLocaleDateString());
    }

    return dates.reverse();
  };
  const dateCategories = getLastSevenDates();
  const [graph12, setGraph12] = useState({
    series: [
      {
        name: "Work cell 1",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        labels: {
          // text: "Shift1, Shift2, Shift3",
        },
      },
      stroke: {
        width: 2,
      },
      // title: {
      //   text: "Throughput",
      // },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: dateCategories,
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Number of Packs",
        },
      },
      tooltip: {
        // custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        //   const data =
        //     w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        //   return (
        //     "<div class='tooltip'>" +
        //     "<h3><b>" +
        //     w.config.series[seriesIndex].name +
        //     "</b></h3>" +
        //     "<ul>" +
        //     "<li>Shift1 : " +
        //     data +
        //     "</li>" +
        //     "<li>Shift2 : " +
        //     0 +
        //     "</li>" +
        //     "<li>Shift3 : " +
        //     0 +
        //     "</li>" +
        //     "</ul>" +
        //     "</div>"
        //   );
        // },
        enabled: true,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          // stops: [50, 0, 100],
        },
      },
    },
  });
  const [chart5, setChart5] = useState({
    series: chart5Data?.map((obj: any) => {
      return {
        name: obj?.username,
        data: [0, 0, 0, 0],
      };
    }),
    options: {
      chart: {
        type: "bar",
        height: 350,
        width: "100%",
        stacked: true,
        dropShadow: {
          enabled: true,
          blur: 1,
          opacity: 0.25,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
        },
        heatmap: {
          colorScale: {
            ranges: [
              {
                from: 1,
                to: 5,
                color: "#8B0000",
                // name: 'low',
                show: false,
              },
              {
                from: 6,
                to: 10,
                color: "#FF0000",
                // name: 'low',
                show: false,
              },
              {
                from: 11,
                to: 20,
                color: "#FF8C00",
                // name: 'low',
                show: false,
              },
              {
                from: 21,
                to: 80,
                color: "#FFA500",
                // name: 'low',
                show: false,
              },
              {
                from: 81,
                to: 200,
                color: "#FFD700",
                // name: 'low',
                show: false,
              },
              {
                from: 201,
                to: 500,
                color: "#FFFF00",
                // name: 'medium',
                show: false,
              },
              {
                from: 501,
                to: 1000,
                color: "#7FFF00",
                // name: 'medium',
                show: false,
              },
              {
                from: 1001,
                to: 1500,
                color: "#32CD32",
                // name: 'high',
                show: false,
              },
              {
                from: 1501,
                to: 1000000,
                color: "#00FF00",
                // name: 'high',
                show: false,
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },

      // title: {
      //   text: "Performance Matrix",
      // },
      xaxis: {
        categories: [
          "Daily Prep",
          "HIS 1",
          "HIS 2",
          "HIS 3",
          "HIS 4",
          "HIS 5",
          "HIS 6",
          "Module Packaging",
        ],
      },
      yaxis: {
        title: {
          text: "Operators",
          cssClass: "operator_title",
        },
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
      fill: {
        type: "pattern",
        opacity: 1,
        pattern: {
          style: [
            "circles",
            "slantedLines",
            "verticalLines",
            "horizontalLines",
          ],
        },
      },
      states: {
        hover: {
          filter: "none",
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
    },
  });
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner" id="kpi">
        <div className="sm-stepForm-banner">
          <SopHeader title={"Assembler KPI"} link={""} />
        </div>
        <ChartContainer>
          <div className="sm-stepForm-wrap">
            <div className="sm-colm-12">
              <div className="sm-commen-box common common-kpi">
                <div className="graph_heading">
                  <h4>Facility Contribution</h4>
                  <div className="graph_filter"></div>
                </div>

                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item p={0}>
                    <div
                      className="graph_heading"
                      style={{ justifyContent: "start" }}
                    >
                      <div className="graph_header">
                        <div className="facility_graph">
                          <div
                            className="item"
                            style={{ fontWeight: "600", fontSize: "22px" }}
                          >
                            Daily
                          </div>{" "}
                          <div
                            className="container-graph"
                            style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
                          >
                            <div className="item"></div>
                            <div className="item">Actual</div>
                            <div className="item">Target</div>
                            <div className="item">Delta</div>

                            <div className="item">
                              <div className="sub-item">Work Cell 1</div>
                              <div className="sub-item">Work Cell 2</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && +countData[0]?.count > 20
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countLoading
                                  ? 0
                                  : countData
                                  ? countData[0]?.count
                                  : 0}
                              </div>
                              <div className="sub-item green"> 0</div>
                            </div>
                            <div className="item">
                              <div className="sub-item">20</div>
                              <div className="sub-item">0</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && countData[0]?.count - 20 >= 0
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countData && countData[0]?.count - 20}
                              </div>
                              <div
                                className={`sub-item ${
                                  countData && countData[0]?.count - 0 >= 0
                                    ? "green"
                                    : "green"
                                }`}
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="facility_graph">
                          <div
                            className="item"
                            style={{ fontWeight: "600", fontSize: "22px" }}
                          >
                            Weekly
                          </div>
                          <div className="container-graph">
                            <div className="item  hide-tab"></div>
                            <div className="item">Actual</div>
                            <div className="item">Target</div>
                            <div className="item">Delta</div>

                            <div className="item hide-tab">
                              <div className="sub-item">Work Cell 2</div>
                              <div className="sub-item">Work Cell 2</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && +countData[1]?.count > 100
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countLoading
                                  ? 0
                                  : countData
                                  ? countData[1]?.count
                                  : 0}
                              </div>
                              <div className="sub-item green"> 0</div>
                            </div>
                            <div className="item">
                              <div className="sub-item">100</div>
                              <div className="sub-item">0</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && countData[1]?.count - 100 >= 0
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countData && countData[1]?.count - 100}
                              </div>
                              <div
                                className={`sub-item ${
                                  countData && countData[0]?.count - 0 >= 0
                                    ? "green"
                                    : "green"
                                }`}
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="facility_graph">
                          <div
                            className="item"
                            style={{ fontWeight: "600", fontSize: "22px" }}
                          >
                            Monthly
                          </div>
                          <div className="container-graph">
                            <div className="item  hide-tab"></div>
                            <div className="item">Actual</div>
                            <div className="item">Target</div>
                            <div className="item">Delta</div>
                            <div className="item hide-tab">
                              <div className="sub-item">Work Cell 2</div>
                              <div className="sub-item">Work Cell 2</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && +countData[2]?.count > 400
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countLoading
                                  ? 0
                                  : countData
                                  ? countData[2]?.count
                                  : 0}
                              </div>
                              <div className="sub-item green"> 0</div>
                            </div>
                            <div className="item">
                              <div className="sub-item">400</div>
                              <div className="sub-item">0</div>
                            </div>
                            <div className="item">
                              <div
                                className={`sub-item ${
                                  countData && countData[2]?.count - 400 >= 0
                                    ? "green"
                                    : "red"
                                }`}
                              >
                                {countData && countData[2]?.count - 400}
                              </div>
                              <div
                                className={`sub-item
                               ${
                                 countData && countData[0]?.count - 0 >= 0
                                   ? "green"
                                   : "green"
                               } 
                              `}
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>{" "}
              {/* GRAPH 2 LATE ENTRY */}
              <div className="sm-commen-box common-kpi">
                <div className="graph_heading">
                  <h4>Monthly Facility Contribution</h4>
                  <div className="graph_filter"></div>
                </div>
                {/* <div className="graph_heading">
                  <div className="graph_filter">
                    <DatePicker
                      selected={startDate8}
                      onChange={(dates: any) => {
                        const [start, end] = dates;
                        setStartDate8(start);
                        setEndDate8(end);
                      }}
                      startDate={startDate8}
                      endDate={endDate8}
                      selectsRange
                      isClearable
                      placeholderText="Select a date range"
                      className="dateRange"
                    />
                  </div>

                  <div className="graph_filter">
                    <Button
                      style={{ minHeight: "35px", lineHeight: "22px" }}
                      // onClick={() => handleGraph2({ type: "filter" })}
                      disabled={loading8}
                    >
                      Filter
                    </Button>
                    <Button
                      style={{ minHeight: "35px", lineHeight: "22px" }}
                      // onClick={() => handleGraph2({ type: "clear" })}
                      disabled={loading8}
                    >
                      Clear
                    </Button>
                  </div>
                </div> */}
                {loading8 ? (
                  <button className="contained">
                    <span className="report_loader"></span>
                  </button>
                ) : (
                  <ReactApexChart
                    options={graph8.options as any}
                    series={graph8.series as any}
                    type="bar"
                    height={350}
                  />
                )}
              </div>
              {/* GRAPH 2 */}
              <div className="sm-commen-box common-kpi">
                <div className="graph_heading">
                  <h4>Production Efficiency</h4>
                </div>
                <Grid container spacing={3} flexDirection={"column"}>
                  <Grid xs={12} item width={"100%"} maxWidth={"100%"}>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate1}
                          onChange={(dates: any) => {
                            const [start, end] = dates;
                            setStartDate1(start);
                            setEndDate1(end);
                          }}
                          startDate={startDate1}
                          endDate={endDate1}
                          selectsRange
                          isClearable
                          placeholderText="Select a date range"
                          className="dateRange"
                        />
                      </div>

                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph1({ type: "filter" })}
                          disabled={loading1}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph1({ type: "clear" })}
                          disabled={loading1}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading1 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={graph2.options as any}
                        series={graph2.series as any}
                        type="bar"
                        height={350}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* GRAPH 3 */}
              <div className="sm-commen-box common common-kpi">
                <div className="graph_heading">
                  <h4>Cycle time</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate5}
                          onChange={(dates: any) => {
                            const [start, end] = dates;
                            setStartDate5(start);
                            setEndDate5(end);
                          }}
                          startDate={startDate5}
                          endDate={endDate5}
                          selectsRange
                          isClearable
                          placeholderText="Select a date range"
                          className="dateRange"
                        />
                      </div>

                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraphLast({ type: "filter" })}
                          disabled={loading5}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraphLast({ type: "clear" })}
                          disabled={loading5}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid sm={12} md={13} xs={12} item>
                    <div className="graph-container">
                      <div className="graph-item">Target</div>
                      <div className="graph-item">3:45 Min</div>
                      {graphModel?.value !== "AAB" ? (
                        <div className="graph-item">3:15 Min</div>
                      ) : (
                        <div className="graph-item">1:45 Min</div>
                      )}
                      {graphModel?.value === "AAB" ? (
                        <div className="graph-item">2:00 Min</div>
                      ) : (
                        <div className="graph-item">3:15 Min</div>
                      )}
                      <div className="graph-item">180:00 Min</div>
                      <div className="graph-item">10:00 Min</div>
                      <div className="graph-item">1080:00 Min</div>

                      {timeArray &&
                        timeArray?.length > 0 &&
                        timeArray?.map((obj: any) => {
                          return (
                            <>
                              <div
                                className="graph-item"
                                style={{ textTransform: "capitalize" }}
                              >
                                {obj?.name}
                              </div>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">
                                      {" "}
                                      Module Inspection
                                    </div>
                                    <div className="tooltip-prefix">
                                      Pack id:{" "}
                                      <label className="tooltip-value">
                                        {obj?.moduleCountsModuleInspection || 0}
                                      </label>
                                    </div>{" "}
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    obj?.moduleInspectionMinutes - 3.45 > 0
                                      ? "red"
                                      : "green"
                                  } `}
                                >
                                  {obj?.moduleInspectionMinutes
                                    ? `${(
                                        obj?.moduleInspectionMinutes - 3.45
                                      ).toFixed(2)} Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">QIS 1</div>
                                    <div className="tooltip-prefix">
                                      Pack id:{" "}
                                      <label className="tooltip-value">
                                        {obj?.moduleCountsQis1 || 0}
                                      </label>
                                    </div>{" "}
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    graphModel?.value !== "AAB"
                                      ? obj?.qis1Minutes - 3.15 > 0
                                        ? "red"
                                        : "green"
                                      : obj?.qis1Minutes - 1.45 > 0
                                      ? "red"
                                      : "green"
                                  } `}
                                >
                                  {obj?.qis1Minutes
                                    ? graphModel?.value !== "AAB"
                                      ? `${(obj?.qis1Minutes - 3.15).toFixed(
                                          2
                                        )} Min`
                                      : `${(obj?.qis1Minutes - 1.45).toFixed(
                                          2
                                        )}\u00A0Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">QIS 2</div>
                                    <div className="tooltip-prefix">
                                      Pack id:{" "}
                                      <label className="tooltip-value">
                                        {obj?.moduleCountsQis2 || 0}
                                      </label>
                                    </div>{" "}
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    graphModel?.value === "AAB"
                                      ? obj?.qis2Minutes - 2.0 > 0
                                        ? "red"
                                        : "green"
                                      : obj?.qis2Minutes - 3.15 > 0
                                      ? "red"
                                      : "green"
                                  } `}
                                >
                                  {obj?.qis2Minutes
                                    ? `${
                                        graphModel?.value === "AAB"
                                          ? (obj?.qis2Minutes - 2.0).toFixed(2)
                                          : (obj?.qis2Minutes - 3.15).toFixed(2)
                                      } Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">QIS 3</div>
                                    <div className="tooltip-prefix">
                                      Pack id:{" "}
                                      <label className="tooltip-value">
                                        {obj?.moduleCountsQis3 || 0}
                                      </label>
                                    </div>{" "}
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    obj?.qis3Minutes - 180 > 0 ? "red" : "green"
                                  } `}
                                >
                                  {obj?.qis3Minutes
                                    ? `${(obj?.qis3Minutes - 180).toFixed(
                                        2
                                      )} Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">QIS 4</div>
                                    <div className="tooltip-prefix">
                                      Pack id:{" "}
                                      <label className="tooltip-value">
                                        {obj?.moduleCountsQis4 || 0}
                                      </label>
                                    </div>{" "}
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    obj?.qis4Minutes - 10 > 0 ? "red" : "green"
                                  } `}
                                >
                                  {obj?.qis4Minutes
                                    ? `${(obj?.qis4Minutes - 10).toFixed(
                                        2
                                      )} Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                              <Tooltip
                                classes={{
                                  tooltip: "custom-tooltip",
                                  arrow: "custom-arrow",
                                }}
                                title={
                                  <div className="tooltip-container">
                                    <div className="tooltip-header">
                                      Pack Packaging
                                    </div>
                                    <div className="tooltip-prefix">
                                      Pack id:{" "}
                                      <label className="tooltip-value">
                                        {obj?.moduleCountsPackPackaging || 0}
                                      </label>
                                    </div>{" "}
                                    {/* {obj?.averageSoh && (
                                      <div className="tooltip-value">{`Ave. SOH: ${obj?.averageSoh.toFixed(
                                        2
                                      )}`}</div>
                                    )} */}
                                  </div>
                                }
                              >
                                <div
                                  className={`graph-item ${
                                    obj?.packPackagingMinutes - 1080 > 0
                                      ? "red"
                                      : "green"
                                  } `}
                                >
                                  {obj?.packPackagingMinutes
                                    ? `${(
                                        obj?.packPackagingMinutes - 1080
                                      ).toFixed(2)} Min`
                                    : "N/A"}
                                </div>
                              </Tooltip>
                            </>
                          );
                        })}

                      <div className="graph-item"></div>
                      <div className="graph-item ">Module Inspection</div>
                      <div className="graph-item ">QIS 1</div>
                      <div className="graph-item ">QIS 2</div>
                      <div className="graph-item ">QIS 3</div>
                      <div className="graph-item ">QIS 4</div>
                      <div className="graph-item ">Pack Packaging</div>
                    </div>
                    {/* </div> */}
                  </Grid>
                </Grid>
              </div>
              {/* GRAPH 4 */}
              <div className="sm-commen-box common-kpi">
                <div className="graph_heading">
                  <h4> Inventory Management</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate3}
                          onChange={(dates: any) => {
                            const [start, end] = dates;
                            setStartDate3(start);
                            setEndDate3(end);
                          }}
                          startDate={startDate3}
                          endDate={endDate3}
                          selectsRange
                          isClearable
                          placeholderText="Select a date range"
                          className="dateRange"
                        />
                      </div>

                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph3({ type: "filter" })}
                          disabled={loading3}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph3({ type: "clear" })}
                          disabled={loading3}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading3 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={graph4.options as any}
                        series={graph4.series as any}
                        type="bar"
                        height={350}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* GRAPH 5.1 & 5.2 */}
              <div className="sm-commen-box common-kpi" style={{display:"none"}}>
                <div className="graph_heading">
                  <h4> Average Inventory Age</h4>
                </div>
                <div>
                  <Grid container spacing={3}>
                    <Grid sm={12} md={12} xs={12} item>
                      <div className="graph_heading">
                        <div className="graph_filter">
                          <DatePicker
                            selected={startDate3}
                            onChange={(dates: any) => {
                              const [start, end] = dates;
                              setStartDate3(start);
                              setEndDate3(end);
                            }}
                            startDate={startDate3}
                            endDate={endDate3}
                            selectsRange
                            isClearable
                            placeholderText="Select a date range"
                            className="dateRange"
                          />
                        </div>

                        <div className="graph_filter">
                          <Button
                            style={{ minHeight: "35px", lineHeight: "22px" }}
                            onClick={() => handleGraph3({ type: "filter" })}
                            disabled={loading3}
                          >
                            Filter
                          </Button>
                          <Button
                            style={{ minHeight: "35px", lineHeight: "22px" }}
                            onClick={() => handleGraph3({ type: "clear" })}
                            disabled={loading3}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                      {loading3 ? (
                        <button className="contained">
                          <span className="report_loader"></span>
                        </button>
                      ) : (
                        <ReactApexChart
                          options={graph5One.options as any}
                          series={graph5One.series as any}
                          type="bar"
                          height={350}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>

                <ReactApexChart
                  options={graph5Two.options as any}
                  series={graph5Two.series as any}
                  type="bar"
                  height={350}
                />
              </div>
              {/* GRAPH 6 */}
              <div className="sm-commen-box common-kpi" style={{display:"none"}}>
                <div className="graph_heading">
                  <h4> Purchase Order</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate6}
                          onChange={(dates: any) => {
                            const [start, end] = dates;
                            setStartDate6(start);
                            setEndDate6(end);
                          }}
                          startDate={startDate6}
                          endDate={endDate6}
                          selectsRange
                          isClearable
                          placeholderText="Select a date range"
                          className="dateRange"
                        />
                      </div>

                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph3({ type: "filter" })}
                          disabled={loading6}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph3({ type: "clear" })}
                          disabled={loading6}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading6 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={graph6.options as any}
                        series={graph6.series as any}
                        type="bar"
                        height={350}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* GRAPH 7 */}
              <div className="sm-commen-box common-kpi">
                <div className="graph_heading">
                  <h4>Defects</h4>
                </div>
                <Grid container spacing={3} flexDirection={"column"}>
                  <Grid
                    sm={12}
                    md={12}
                    xs={12}
                    item
                    // width={"100%"}
                    // maxWidth={"100%"}
                    // style={{ paddingLeft: "8px" }}
                  >
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate7}
                          onChange={(dates: any) => {
                            const [start, end] = dates;
                            setStartDate7(start);
                            setEndDate7(end);
                          }}
                          startDate={startDate7}
                          endDate={endDate7}
                          selectsRange
                          isClearable
                          placeholderText="Select a date range"
                          className="dateRange"
                        />
                      </div>

                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph2({ type: "filter" })}
                          disabled={loading7}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          onClick={() => handleGraph2({ type: "clear" })}
                          disabled={loading7}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading7 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={graph7.options as any}
                        series={graph7.series as any}
                        type="bar"
                        height={400}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* GRAPH 8 */}
              <div className="sm-commen-box common-kpi" style={{display:"none"}}>
                <div className="graph_heading">
                  <h4>Scrap Rate</h4>
                  <div className="graph_filter"></div>
                </div>
                {/* <div className="graph_heading">
                  <div className="graph_filter">
                    <DatePicker
                      selected={startDate8}
                      onChange={(dates: any) => {
                        const [start, end] = dates;
                        setStartDate8(start);
                        setEndDate8(end);
                      }}
                      startDate={startDate8}
                      endDate={endDate8}
                      selectsRange
                      isClearable
                      placeholderText="Select a date range"
                      className="dateRange"
                    />
                  </div>

                  <div className="graph_filter">
                    <Button
                      style={{ minHeight: "35px", lineHeight: "22px" }}
                      // onClick={() => handleGraph2({ type: "filter" })}
                      disabled={loading8}
                    >
                      Filter
                    </Button>
                    <Button
                      style={{ minHeight: "35px", lineHeight: "22px" }}
                      // onClick={() => handleGraph2({ type: "clear" })}
                      disabled={loading8}
                    >
                      Clear
                    </Button>
                  </div>
                </div> */}
                {loading8 ? (
                  <button className="contained">
                    <span className="report_loader"></span>
                  </button>
                ) : (
                  <ReactApexChart
                    options={graph8.options as any}
                    series={graph8.series as any}
                    type="bar"
                    height={350}
                  />
                )}
              </div>
              {/* GRAPH 9 */}
              <div className="sm-commen-box common-kpi" style={{display:"none"}}>
                <div className="graph_heading">
                  <h4> Production Lead Time</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate9}
                          onChange={(dates: any) => {
                            const [start, end] = dates;
                            setStartDate9(start);
                            setEndDate9(end);
                          }}
                          startDate={startDate9}
                          endDate={endDate9}
                          selectsRange
                          isClearable
                          placeholderText="Select a date range"
                          className="dateRange"
                        />
                      </div>

                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          // onClick={() => handleGraph3({ type: "filter" })}
                          disabled={loading9}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          // onClick={() => handleGraph3({ type: "clear" })}
                          disabled={loading9}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading9 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={graph9.options as any}
                        series={graph9.series as any}
                        type="bar"
                        height={350}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* GRAPH 10 */}
              <div className="sm-commen-box common-kpi" style={{display:"none"}}>
                <div className="graph_heading">
                  <h4> Rework Rate</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate10}
                          onChange={(dates: any) => {
                            const [start, end] = dates;
                            setStartDate10(start);
                            setEndDate10(end);
                          }}
                          startDate={startDate10}
                          endDate={endDate10}
                          selectsRange
                          isClearable
                          placeholderText="Select a date range"
                          className="dateRange"
                        />
                      </div>

                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          // onClick={() => handleGraph3({ type: "filter" })}
                          disabled={loading10}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          // onClick={() => handleGraph3({ type: "clear" })}
                          disabled={loading10}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading10 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={graph10.options as any}
                        series={graph10.series as any}
                        type="bar"
                        height={350}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* GRAPH 11 */}
              <div className="sm-commen-box common-kpi" style={{display:"none"}}>
                <div className="graph_heading">
                  <h4> Quality Control</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid sm={12} md={12} xs={12} item>
                    <div className="graph_heading">
                      <div className="graph_filter">
                        <DatePicker
                          selected={startDate11}
                          onChange={(dates: any) => {
                            const [start, end] = dates;
                            setStartDate11(start);
                            setEndDate11(end);
                          }}
                          startDate={startDate11}
                          endDate={endDate11}
                          selectsRange
                          isClearable
                          placeholderText="Select a date range"
                          className="dateRange"
                        />
                      </div>

                      <div className="graph_filter">
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          // onClick={() => handleGraph3({ type: "filter" })}
                          disabled={loading11}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{ minHeight: "35px", lineHeight: "22px" }}
                          // onClick={() => handleGraph3({ type: "clear" })}
                          disabled={loading11}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                    {loading11 ? (
                      <button className="contained">
                        <span className="report_loader"></span>
                      </button>
                    ) : (
                      <ReactApexChart
                        options={graph11.options as any}
                        series={graph11.series as any}
                        type="bar"
                        height={350}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* GRAPH 12 */}
              {/* GRAPH 8 */}
              <div className="sm-commen-box common-kpi" style={{display:"none"}}>
                <div className="graph_heading">
                  <h4>On-Time Delivery</h4>
                  <div className="graph_filter"></div>
                </div>
                <div className="graph_heading">
                  <div className="graph_filter">
                    <DatePicker
                      selected={startDate12}
                      onChange={(dates: any) => {
                        const [start, end] = dates;
                        setStartDate12(start);
                        setEndDate12(end);
                      }}
                      startDate={startDate12}
                      endDate={endDate12}
                      selectsRange
                      isClearable
                      placeholderText="Select a date range"
                      className="dateRange"
                    />
                  </div>

                  <div className="graph_filter">
                    <Button
                      style={{ minHeight: "35px", lineHeight: "22px" }}
                      // onClick={() => handleGraph2({ type: "filter" })}
                      disabled={loading12}
                    >
                      Filter
                    </Button>
                    <Button
                      style={{ minHeight: "35px", lineHeight: "22px" }}
                      // onClick={() => handleGraph2({ type: "clear" })}
                      disabled={loading12}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
                {loading12 ? (
                  <button className="contained">
                    <span className="report_loader"></span>
                  </button>
                ) : (
                  <ReactApexChart
                    options={graph12.options as any}
                    series={graph12.series as any}
                    type="bar"
                    height={350}
                  />
                )}
              </div>
            </div>
          </div>
        </ChartContainer>
      </div>
    </React.Fragment>
  );
};
export default QISKPI;
